html {
  font-size: $font-size-root;
}

body {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  color:#888888;
}

.wrapper {
  &.wrapper-full-page {
    height: auto;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

.content-wrapper {
  padding: 0 30px;
  margin-top: 8%;
}

.serif-font {
  //font-family: $font-family-sans-serif;
  font-family: "Montserrat",
      "Segoe UI",
      Roboto,
      "Helvetica Neue",
      Arial,
      sans-serif !important;
}

a {
  &:focus {
    color: darken(theme-color("primary"), 5%);
    text-decoration: none;
  }

  &.text-info {
    &:hover,
    &:focus {
      //color: darken(theme-color("info"), 5%);
      color: darken(red, 5%);
    }
  }
}

p {
  letter-spacing: 0.3px;
}

@media (max-width: 576px) {
  .main-panel {
    .main-content {
      .content-wrapper {
        padding: 0 15px;
      }
    }
  }
}
