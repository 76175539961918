// basic badge
.badge{
	letter-spacing: 0.3px;
	//background-color: $primary;
	background-color: #888888;
	color: $white;

	&.badge-light {
		//color: $body-color;
		color: white;
	}

	// round badge
	&.badge-round {
		padding: 6px 8px;
	}
}
