/* You can add global styles to this file, and also import other style files */
.form-control{
    color: black !important;
}
.menu-title{
    color: black !important;
}
body{
    color: black !important;
}

table {
    color: black !important;
}

.hide{
    display: none;
}
.redStar {
    color: red;
}


.page-heading {
  font-weight: bold;
  color: black !important;
  /* border-left: 1px solid darkblue; */
  padding-left: 5px;
}

.btn-add{
  background-color: rgb(3, 145, 97) !important;
  color: white !important;
}

.btn-add:hover{
  background-color: rgb(19, 165, 116) !important;
}

.btn-gotolist{
  background-color: rgb(161, 157, 157) !important;
  color: white !important;
}

.btn-gotoadd {
  background-color: orange !important;
  color: white !important;
}

.btn-inneradd {
  background-color: lightgrey !important;
  color: black !important;
}

.btn-back {
  background-color: cornflowerblue !important;
  color: white;
}

